import { getContractAIData, getGraphData } from '../../../utilities/microsoft-graph-helpers'
import { delay, generateWordDoc } from '../../../utilities/office-apis-helpers'
import { AppState, ClauseList } from '../AppHelpers'

export interface HeaderListInterface {
  headerSearchText: string
  name: string
  clause: ClauseList[]
  risk: RiskAnalysis
}
export interface ContractClauseInterface {
  id: number
  requirement: string
  description: string
  is_default: boolean
  contract_clause_type_id: number
}

export interface ClauseListInterface {
  id: number
  instance_id: number
  name: string
  ai_prompt: string
  ai_prompt_enabled_default: boolean
  section: number
  contract_clauses: ContractClauseInterface[]
}

export interface RiskAnalysis {
  riskLevel: string
  description: string
}

export interface GCSUploadData {
  attachment_uuid: string
  attachment_id: number
  generation: number
  file_name: string
  sharepoint_item_id: string
  mime_type: string
  instance_id: number
  is_coAuthoring: boolean
}

export interface AttachmentEditSesssion {
  attachment_id: number
  attachment_typename: string
  attachment_uuid: string
  file_name: string
  generation: number
  instance_id: number
  is_active: boolean
  is_coAuthoring: boolean
  mime_type: string
  object_type: string
  object_uuid: string
  sharepoint_item_id: string
  user_email: string
  user_id: string
}

export const scrollToposition = async (searchString: string) => {
  try {
    await Word.run(async (context) => {
      let found = false
      try {
        const searchRes3 = context.document.search(`${searchString.trim()}\n`, {
          ignorePunct: true,
          ignoreSpace: false,
          matchCase: true,
        })
        searchRes3.load('items')
        await context.sync()
        if (searchRes3.items?.length > 0) {
          searchRes3.items[0]?.select('Select')
          found = true
        }
      } catch (error) {
        console.log('Error', error)
      }
      if (!found) {
        try {
          const searchRes2 = context.document.search(`${searchString.trim()}\r`, {
            ignorePunct: true,
            ignoreSpace: false,
            matchCase: true,
          })
          searchRes2.load('items')
          await context.sync()
          if (searchRes2.items?.length > 0) {
            found = true
            searchRes2.items[0]?.select('Select')
          }
        } catch (error) {
          console.log('Error', error)
        }
      }
      if (!found) {
        try {
          const searchRes = context.document.search(`${searchString.trim()}`, {
            ignorePunct: true,
            ignoreSpace: true,
            matchCase: true,
          })
          searchRes.load(['items', 'style', 'styleBuiltIn'])
          await context.sync()
          if (searchRes.items?.length > 0) {
            const filteredItems = searchRes.items?.filter((item) =>
              ['Heading 1', 'Heading 2', 'Heading 3', 'item.isListItem'].includes(item.style)
            )
            if (filteredItems.length > 0) {
              filteredItems?.[0].select('Select')
            } else {
              searchRes.items[0].select('Select')
            }
          }
        } catch (error) {
          console.log('Scroll error', error)
        }
      }
    })
  } catch (error) {
    console.log('Scroll error', error)
  }
}
export const getHeadersFromDoc = async (
  setHeaderList: React.Dispatch<React.SetStateAction<HeaderListInterface[]>>,
  clauseList: ClauseList[],
  token: string
) => {
  await Word.run(async (context) => {
    // Create a proxy object for the document body.
    const body = context.document.body
    body.load('text')
    await context.sync()
    const trackedChanges = body.getReviewedText('Current')
    await context.sync()
    const docText = trackedChanges.value
    const prompt_text = `Get clause headings from the contract. The headings must match the contract numbering exactly.If numbering is available for that heading,return heading with number. Do not add extra number to headings that are not numbered on the context. Context:${docText}`
    const data = {
      contract_prompt_text: prompt_text,
    }
    let response = await getContractAIData(`https://${process.env.REACT_APP_RAINAI}/aigenerate`, token, data)
    if (typeof response.data === 'string') {
      const headers: HeaderListInterface[] = []
      const formattedText = generateWordDoc(response.data)
      JSON.parse(JSON.stringify(formattedText)).forEach((para) => {
        const headerItem: HeaderListInterface = {
          headerSearchText: '',
          name: '',
          clause: undefined,
          risk: undefined,
        }
        // writeTexttoWordFile(para.children?.[0]?.text, null, 12, false)
        headers.push({
          ...headerItem,
          headerSearchText: para.children?.[0]?.text.replace(/[\*]/g, ''),
          name: para.children?.[0]?.text.replace(/[\*0-9.-]/g, '').trim(),
        })
        //}
      })
      const headersWithClauses = getClauseTypeHeaderMapping(JSON.parse(JSON.stringify(headers)), clauseList)
      setHeaderList(headersWithClauses)
    }
  })
}

export const getSelectedTextfromDocument = async () => {
  let docText = ''
  await Word.run(async (context) => {
    const body = context.document.getSelection()
    body.load('text')
    await context.sync()
    const trackedChanges = body.getReviewedText('Current')
    await context.sync()
    docText = trackedChanges.value
  })
  return docText
}

export const getRiskAnalysisfromDocument = async (
  headerList: HeaderListInterface[],
  setHeaderList: React.Dispatch<React.SetStateAction<HeaderListInterface[]>>,
  setCompletedRiskAnalysis: React.Dispatch<React.SetStateAction<boolean>>,
  token: string,
  text: string
) => {
  await Word.run(async (context) => {
    // Create a proxy object for the document body.
    let docText = text
    if (text === null) {
      const body = context.document.body
      body.load('text')
      await context.sync()
      const trackedChanges = body.getReviewedText('Current')
      await context.sync()
      docText = trackedChanges.value
    }
    const prompt_text = `Get standard rank risk analysis for all clause headers in Context with risk low medium high and include Clause Heading,rank risk, risk analysis detaiks as description format with delimiter as |  Context :   ${docText}`
    const data = {
      contract_prompt_text: prompt_text,
    }

    const headers: HeaderListInterface[] = JSON.parse(JSON.stringify(headerList))
    let response = await getContractAIData(`https://${process.env.REACT_APP_RAINAI}/aigenerate`, token, data)
    if (typeof response.data === 'string') {
      const formattedText = generateWordDoc(response.data)
      JSON.parse(JSON.stringify(formattedText)).forEach((para) => {
        const textStrings = para.children[0]?.text?.split('|')
        if (textStrings.length > 0) {
          let searchString = textStrings[0]?.length > 1 ? textStrings[0] : textStrings[1]
          searchString = searchString.replace(/[\*0-9.-]/g, '').trim()
          const idx = headers.findIndex((header) => header.name.includes(searchString))
          if (idx !== -1) {
            const riskLevel = textStrings[0]?.length > 1 ? textStrings[1] : textStrings[2]
            const description = textStrings[0]?.length > 1 ? textStrings[2] : textStrings[3]
            headers[idx] = {
              ...headers[idx],
              risk: {
                riskLevel: riskLevel,
                description: description.replace(/[\*]/g, ''),
              },
            }
          }
        }
      })
      setCompletedRiskAnalysis(true)
      setTimeout(() => {
        console.log('headers after risk analysis', headers)
        setHeaderList(headers)
      }, 10)
    }
  })
}

export const getRiskAnalysisforSelection = async (token: string, text: string) => {
  let formattedText = ''
  await Word.run(async (context) => {
    // Create a proxy object for the document body.
    let docText = text
    if (text === null) {
      const body = context.document.body
      body.load('text')
      await context.sync()
      const trackedChanges = body.getReviewedText('Current')
      await context.sync()
      docText = trackedChanges.value
    }
    const prompt_text = `you are a contract risk analysis agent. 
    Review the document under "Context:" and  run standard risk analysis. Context: ${docText}`
    const data = {
      contract_prompt_text: prompt_text,
    }
    let response = await getContractAIData(`https://${process.env.REACT_APP_RAINAI}/aigenerate`, token, data)
    if (typeof response.data === 'string') {
      const generatedText = generateWordDoc(response.data)
      generatedText?.forEach((para) => {
        formattedText += `${para.children?.[0]?.text}`
      })
    }
  })
  return formattedText
}

export const getAttachmentDetails = async (
  setAppState: React.Dispatch<React.SetStateAction<AppState>>,
  token: string
) => {
  try {
    let attachmentEditSession: AttachmentEditSesssion = {
      attachment_id: 0,
      attachment_typename: '',
      attachment_uuid: '',
      file_name: '',
      generation: 0,
      instance_id: 0,
      is_active: false,
      is_coAuthoring: false,
      mime_type: '',
      object_type: '',
      object_uuid: '',
      sharepoint_item_id: '',
      user_email: '',
      user_id: '',
    }
    let response = await getGraphData(
      // Call backend api to fetch clauses
      'https://' + process.env.REACT_APP_RAINAI + '/getattachmenteditsession',
      token
    )
    if (response.data?.data?.attachment_edit_session?.[0]) {
      const data = response.data?.data?.attachment_edit_session?.[0]
      attachmentEditSession = JSON.parse(JSON.stringify(data))
      attachmentEditSession.user_id = data.user?.id
      setAppState((prev) => ({ ...prev, attachmentEditSession: attachmentEditSession }))
    }
  } catch (requestError) {
    // This error must be from the Axios request in getGraphData,
    // not the Office.js in writeFileNamesToWorksheet.
    console.log('error', requestError)
  }
  return undefined
}

export const getClauseTypeHeaderMapping = (headerList: HeaderListInterface[], clauseList: ClauseList[]) => {
  console.log('inital Headers', headerList)
  const newHeaderList: HeaderListInterface[] = []
  headerList.forEach((header) => {
    const newHeader = JSON.parse(JSON.stringify(header))
    console.log('clauseList', clauseList)
    const clauseMap = clauseList.filter((clause) => clause?.type?.toLowerCase() === header.name?.toLowerCase())
    if (clauseMap.length > 0) {
      newHeader.clause = clauseMap
    }
    newHeaderList.push(newHeader)
  })
  console.log(newHeaderList)
  return newHeaderList
}

export const getTextBetweenHeaders = async (startHeader: string, nextHeader: string) => {
  let startRange: Word.Range = null
  let endRange: Word.Range = null
  let text = ''
  await Word.run(async (context) => {
    try {
      const start_1 = context.document.body.search(`${startHeader.trim()}\n`, {
        ignorePunct: true,
        ignoreSpace: false,
        matchCase: true,
      })
      start_1.load('items')
      await context.sync()
      if (start_1.items?.length > 0) {
        startRange = start_1.items[0]
      }
      const end_1 = context.document.search(`${nextHeader.trim()}\n`, {
        ignorePunct: true,
        ignoreSpace: false,
        matchCase: true,
      })
      end_1.load('items')
      await context.sync()
      if (end_1.items?.length > 0) {
        endRange = end_1.getFirst()
      }
      if (startRange === null) {
        const start_2 = context.document.search(`${startHeader.trim()}\r`, {
          ignorePunct: true,
          ignoreSpace: false,
          matchCase: true,
        })
        start_2.load('items')
        await context.sync()
        if (start_2.items?.length > 0) {
          startRange = start_2.items[0]
        }
        if (startRange === null) {
          const start_3 = context.document.search(`${startHeader.trim()}`, {
            ignorePunct: true,
            ignoreSpace: true,
            matchCase: true,
          })
          start_3.load(['items', 'style', 'styleBuiltIn'])
          await context.sync()
          if (start_3.items?.length > 0) {
            const filteredItems = start_3.items?.filter((item) =>
              ['Heading 1', 'Heading 2', 'Heading 3', 'item.isListItem'].includes(item.style)
            )
            if (filteredItems.length > 0) {
              startRange = filteredItems?.[0]
            } else {
              startRange = start_3.items[0]
            }
          }
        }
      }
      if (endRange === null) {
        const end_2 = context.document.search(`${nextHeader.trim()}\r`, {
          ignorePunct: true,
          ignoreSpace: false,
          matchCase: true,
        })
        end_2.load('items')
        await context.sync()
        if (end_2.items?.length > 0) {
          endRange = end_2.getFirst()
        }
        if (endRange === null) {
          const end_3 = context.document.search(`${nextHeader.trim()}`, {
            ignorePunct: true,
            ignoreSpace: true,
            matchCase: true,
          })
          end_3.load(['items', 'style', 'styleBuiltIn'])
          await context.sync()
          if (end_3.items?.length > 0) {
            const filteredItems = end_3.items?.filter((item) =>
              ['Heading 1', 'Heading 2', 'Heading 3', 'item.isListItem'].includes(item.style)
            )
            if (filteredItems.length > 0) {
              endRange = filteredItems?.[0]
            } else {
              endRange = end_3.getFirst()
            }
          }
        }
      }
      if (startRange !== null && endRange !== null) {
        const range = startRange.getRange('After').expandTo(endRange.getRange('Start'))
        range.load('text')
        await context.sync()
        text = range.text
      }
    } catch (error) {
      console.log('Scroll error', error)
    }
  })
  return text
}

export const replaceWithLibraryClause = async (startHeader: string, nextHeader: string, replaceText: string) => {
  let startRange: Word.Range = null
  let endRange: Word.Range = null
  await Word.run(async (context) => {
    try {
      const start_1 = context.document.body.search(`${startHeader.trim()}\n`, {
        ignorePunct: true,
        ignoreSpace: false,
        matchCase: true,
      })
      start_1.load('items')
      await context.sync()
      if (start_1.items?.length > 0) {
        startRange = start_1.items[0]
      }
      const end_1 = context.document.search(`${nextHeader.trim()}\n`, {
        ignorePunct: true,
        ignoreSpace: false,
        matchCase: true,
      })
      end_1.load('items')
      await context.sync()
      if (end_1.items?.length > 0) {
        endRange = end_1.getFirst()
      }
      if (startRange === null) {
        const start_2 = context.document.search(`${startHeader.trim()}\r`, {
          ignorePunct: true,
          ignoreSpace: false,
          matchCase: true,
        })
        start_2.load('items')
        await context.sync()
        if (start_2.items?.length > 0) {
          startRange = start_2.items[0]
        }
        if (startRange === null) {
          const start_3 = context.document.search(`${startHeader.trim()}`, {
            ignorePunct: true,
            ignoreSpace: true,
            matchCase: true,
          })
          start_3.load(['items', 'style', 'styleBuiltIn'])
          await context.sync()
          if (start_3.items?.length > 0) {
            const filteredItems = start_3.items?.filter((item) =>
              ['Heading 1', 'Heading 2', 'Heading 3', 'item.isListItem'].includes(item.style)
            )
            if (filteredItems.length > 0) {
              startRange = filteredItems?.[0]
            } else {
              startRange = start_3.items[0]
            }
          }
        }
      }
      if (endRange === null) {
        const end_2 = context.document.search(`${nextHeader.trim()}\r`, {
          ignorePunct: true,
          ignoreSpace: false,
          matchCase: true,
        })
        end_2.load('items')
        await context.sync()
        if (end_2.items?.length > 0) {
          endRange = end_2.getFirst()
        }
        if (endRange === null) {
          const end_3 = context.document.search(`${nextHeader.trim()}`, {
            ignorePunct: true,
            ignoreSpace: true,
            matchCase: true,
          })
          end_3.load(['items', 'style', 'styleBuiltIn'])
          await context.sync()
          if (end_3.items?.length > 0) {
            const filteredItems = end_3.items?.filter((item) =>
              ['Heading 1', 'Heading 2', 'Heading 3', 'item.isListItem'].includes(item.style)
            )
            if (filteredItems.length > 0) {
              endRange = filteredItems?.[0]
            } else {
              endRange = end_3.getFirst()
            }
          }
        }
      }
      if (startRange !== null && endRange !== null) {
        const range = startRange.getRange('After').expandTo(endRange.getRange('Start'))
        // range.select('Select')
        range.insertText(replaceText, Word.InsertLocation.replace)
        await context.sync()
      }
    } catch (error) {
      console.log('Scroll error', error)
    }
  })
}

export const showDialog = async (headerItem: HeaderListInterface, nextHeader: string) => {
  const clauses = []
  headerItem.clause.forEach((hc) => {
    hc.clause_type.forEach((cl) => {
      clauses.push({ name: cl.name, description: cl.description })
    })
  })
  const text = await getTextBetweenHeaders(headerItem.headerSearchText, nextHeader)
  let compareDialog: Office.Dialog
  const dialogUrl: string = `${location.protocol}//${location.hostname}${
    location.port ? ':' + location.port : ''
  }/dialog/compare.html?clauses=${encodeURIComponent(JSON.stringify(clauses))}&oldClause=${encodeURIComponent(
    text.trim()
  )}`
  Office.context.ui.displayDialogAsync(dialogUrl, { height: 40, width: 40 }, async (result) => {
    if (result.status === Office.AsyncResultStatus.Failed) {
      console.log('Dialog failed to open')
    } else {
      compareDialog = result.value
      compareDialog.addEventHandler(Office.EventType.DialogMessageReceived, (arg) =>
        handleDialogMessage(arg, headerItem.headerSearchText, nextHeader, compareDialog)
      )
      await delay(5000) // Wait for dialog to initialize and register handler for messaging.
    }
  })

  const handleDialogMessage = async (message: any, header: string, nextHeader: string, dialog: Office.Dialog) => {
    const res = JSON.parse(message.message)
    if (dialog) {
      dialog.close()
    }
    if (res.newClauseText !== null) {
      await replaceWithLibraryClause(header, nextHeader, res.newClauseText)
    }
  }
}

export const getFullDocAnalysis = async (
  setHeaderList: React.Dispatch<React.SetStateAction<HeaderListInterface[]>>,
  setAnalysisError: React.Dispatch<React.SetStateAction<boolean>>,
  clauseList: ClauseList[],
  token: string
) => {
  await Word.run(async (context) => {
    // Create a proxy object for the document body.
    const body = context.document.body
    body.load('text')
    await context.sync()
    const trackedChanges = body.getReviewedText('Current')
    await context.sync()
    const docText = trackedChanges.value
    const prompt_text = `you are a contract risk analysis agent. Review the document under "Context:" and understand all the clause headers. Identify only clause headers and not any document header. Run standard risk analysis for all the clause headers with risk low, medium, and high Respond with the output to include clause header, risk rank, risk analysis description. Format the output as json json format:{clause_header,risk_rank,risk_analysis_description}. Context:${docText}`
    const data = {
      contract_prompt_text: prompt_text,
    }
    let response = await getContractAIData(`https://${process.env.REACT_APP_RAINAI}/aigenerate`, token, data)
    if (typeof response.data === 'string') {
      console.log('response', response.data)
      const headers: HeaderListInterface[] = []
      let formattedData = response.data.replace('```json', '')
      JSON.parse(formattedData.replace('```', '')).forEach((para) => {
        const headerItem: HeaderListInterface = {
          headerSearchText: '',
          name: '',
          clause: undefined,
          risk: undefined,
        }
        // writeTexttoWordFile(para.children?.[0]?.text, null, 12, false)
        headers.push({
          ...headerItem,
          headerSearchText: para.clause_header,
          name: para.clause_header,
          risk: {
            riskLevel: para.risk_rank,
            description: para.risk_analysis_description,
          },
        })
        //}
      })
      console.log('headers loaded', headers)
      const headersWithNames = headers.filter((header) => header.name?.length > 0)
      if (headersWithNames.length === 0) {
        setAnalysisError(true)
      } else {
        const headersWithClauses = getClauseTypeHeaderMapping(JSON.parse(JSON.stringify(headers)), clauseList)
        setHeaderList(headersWithClauses)
      }
    }
  })
}
