import React, { useState } from 'react'
import ChatBot, { Message } from 'react-chatbotify'
import { getContractAIData } from '../../../../../utilities/microsoft-graph-helpers'
import { RDFontFamily, RDPaletteLight } from '../../../../services/ThemeService'
import { generateWordDoc } from '../../../../../utilities/office-apis-helpers'
// import { diffArrays } from 'diff';
// import ReactDiffViewer, { DiffMethod } from 'react-diff-viewer'
// import TextDiff from '../RDTextDiff'
import {
  // getDataWihStyles,
  getDataWithoutStyles,
  getGeminiRequestPromptParts,
  getUserInputType,
  parseResponse,
} from './RDChatBotifyHelpers'
// import Summary from '../../../Summary'
import RDChatBotifySummary from './RDChatBotifySummary'
import { Typography, Box, LinearProgress } from '@mui/material'
import { RDGrid } from '../RDGrid'
import { Close } from '@material-ui/icons'
import { RDButton } from '../../../RDButton'
// import { RDLoader } from '../RDLoader'
// import { generateWordDoc, writeTexttoWordFile } from '../../../../../utilities/office-apis-helpers'

interface RDChatBotifyState {
  disableUserInput: boolean
  options: string[]
  paths: any[]
  messages: Message[]
  sessionID: string
  loading: boolean
}

export interface RDChatBotifyProps {
  sessionID: string
  token: string
  setBotOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export interface GeminiRequestPromptParts {
  prefix: string
  userMessage: string
  suffix: string
  document: string
}

export interface GenerateAIRequestParams {
  contractId: number
  contract_prompt_text: string
}

const getInitialState = () => {
  return {
    disableUserInput: false,
    options: [],
    paths: [],
    messages: [],
    sessionID: '',
    loading: false,
  }
}

const RDChatBotify = (props: RDChatBotifyProps) => {
  const [rdChatBotifyState, setRdChatBotifyState] = useState<RDChatBotifyState>(getInitialState())
  //console.log(rdChatBotifyState, setRdChatBotifyState)

  const flow = {
    start: {
      message: `Hey there! I am Raindrop's AI Assistant. How may I help you?`,
      path: 'loop',
    },
    loop: {
      message: (params) => {
        run(params)
      },
      path: 'loop',
    },
  }

  const run = (params) => {
    setRdChatBotifyState((prev) => ({ ...prev, loading: true }))
    sendRequest({
      userInput: params?.userInput,
      params,
    })
  }

  const sendRequest = async ({ userInput, params }) => {
    let userInputType = getUserInputType(userInput)
    console.log(userInputType)
    console.log(params)
    setRdChatBotifyState((prev) => ({ ...prev, loading: true, disableUserInput: true }))
    try {
      await Word.run(async (context) => {
        const body = context.document.body

        context.load(body, ['text', 'style'])
        await context.sync()
        const trackedChanges = body.getReviewedText('Current')
        await context.sync()
        const docText = trackedChanges.value
        console.log('docText', docText)
        const { prefix, suffix, document, userMessage } = getGeminiRequestPromptParts(docText, userInput)
        const data: GenerateAIRequestParams = {
          contractId: 43569,
          contract_prompt_text: prefix + userMessage + suffix + document,
        }
        let response = await getContractAIData(`https://${process.env.REACT_APP_RAINAI}/aigenerate`, props.token, data)
        const genDoc = generateWordDoc(response.data)
        console.log(genDoc)
        const parsedResp = parseResponse(response)
        setRdChatBotifyState((prev) => ({ ...prev, loading: false, disableUserInput: false }))

        if (response) {
          if (parsedResp.updatedContract) {
            const oldData = docText
            const oldDataWithoutStyles = getDataWithoutStyles(oldData)
            params.injectMessage(
              <RDGrid direction='column' justifyContent={'space-between'}>
                <RDGrid
                  style={{
                    marginLeft: '16px',
                    marginTop: '8px',
                    backgroundColor: RDPaletteLight.WhiteSmoke,
                    borderRadius: '22px',
                    padding: '12px 16px',
                    maxWidth: '75%',
                  }}>
                  <RDChatBotifySummary response={parsedResp} docText={oldDataWithoutStyles}></RDChatBotifySummary>
                </RDGrid>
              </RDGrid>
            )
          } else {
            params.injectMessage(response.data)
          }
        } else {
          console.error('Unexpected response structure:', response)
          params.injectMessage('Unexpected response from server.')
          setRdChatBotifyState((prev) => ({ ...prev, loading: false, disableUserInput: false }))
        }
      })
    } catch (err) {
      console.error('Error during Axios request:', err)
      params.injectMessage('An error occurred while processing your request.')
    }
    return 'Something Happened'
  }

  const LoaderOverlay = ({ loading }) => {
    return (
      loading && (
        <Box
          sx={{
            position: 'fixed',
            top: '50%',
            left: 0,
            width: '50%',
            height: '50vh',
            backgroundColor: 'rgba(0, 0, 0, 0.0)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 9999,
          }}>
          {/* <CircularProgress style={{ color: RDPaletteLight.raindrop_blue }} size='2rem' /> */}
        </Box>
      )
    )
  }

  return (
    <RDGrid container direction='column' nopaddingtop='true' style={{ width: '100%', left: '50%' }}>
      <BottomPaneHeader loading={rdChatBotifyState.loading} setBotOpen={props.setBotOpen} />
      <LoaderOverlay loading={rdChatBotifyState.loading}></LoaderOverlay>
      <ChatBot
        flow={flow}
        styles={{
          chatButtonStyle: {
            height: '40px',
            width: '40px',
            bottom: '80px',
            right: '30px',
          },
          tooltipStyle: {
            bottom: '80px',
            right: '85px',
            fontSize: '16px',
          },
          chatWindowStyle: {
            width: '100%',
            height: '40vh',
            borderRadius: '0',
            backgroundSize: 'contain',
            backgroundPosition: 'fit',
          },
          botBubbleStyle: {
            color: RDPaletteLight.Black,
            backgroundColor: RDPaletteLight.WhiteSmoke,
            fontSize: '16px',
          },
          userBubbleStyle: {
            fontSize: '16px',
            backgroundColor: RDPaletteLight.raindrop_blue,
            color: RDPaletteLight.Black,
          },
          chatInputAreaStyle: {},
          bodyStyle: {
            height: '100%',
          },
          headerStyle: {
            alignItems: 'center',
            display: 'flex',
            flexWrap: 'nowrap',
            padding: '0px !important',
          },
          sendButtonStyle: {
            backgroundColor: RDPaletteLight.raindrop_blue,
          },
        }}
        settings={{
          // advance: {
          //   useAdvancedMessages: true,
          //   useAdvancedPaths: true,
          // },
          general: {
            primaryColor: '#FFFFFF',
            secondaryColor: '#FFFFFF',
            fontFamily: `"Gotham HTF", "Roboto", "Helvetica Neue", sans-serif`,

            showHeader: false,
            showFooter: false,
            showInputRow: true,

            // actionDisabledIcon: actionDisabledIcon,
            embedded: true,
            desktopEnabled: true,
            mobileEnabled: true,

            flowStartTrigger: 'ON_LOAD',
          },
          header: {
            title: <BottomPaneHeader setBotOpen={props.setBotOpen} />,
            showAvatar: false,
            buttons: [],
          },
          footer: {
            text: 'RaindropAI',
          },
          chatHistory: { disabled: true, storageKey: 'example_smart_conversation' },

          chatInput: {
            disabled: rdChatBotifyState.disableUserInput,
          },
          tooltip: {
            mode: 'CLOSE',
            text: 'Raindrop AI',
          },
          botBubble: {
            animate: true,
          },
        }}
      />
    </RDGrid>
  )
}

export default RDChatBotify

export const BottomPaneHeader = (props) => {
  //console.log(props)
  return (
    <>
      <RDGrid
        id='bottom-pane-header-container'
        container
        direction='row'
        alignItems='center'
        justifyContent={'space-between'}
        wrap='nowrap'
        style={{
          paddingTop: '0px !important',
          height: 40,
          borderBottomColor: 'lightgrey',
          borderBottomWidth: 1,
          borderBottomStyle: 'solid',
          maxHeight: 100,
          paddingRight: 6,
          paddingBottom: 0,
          borderLeftWidth: 6,
          borderLeftColor: RDPaletteLight.raindrop_blue,
          borderLeftStyle: 'solid',
        }}>
        <Typography color='black' fontFamily={RDFontFamily} fontSize={16} paddingLeft={'6px'}>
          Raindrop Assisstant
        </Typography>
        <RDButton variant='text' onClick={() => props.setBotOpen(false)}>
          <Close style={{ height: 20, width: 20, color: 'black' }} />
        </RDButton>
      </RDGrid>
      {props.loading && (
        <RDGrid style={{ width: '100%' }}>
          <LinearProgress />
        </RDGrid>
      )}
    </>
  )
}
