import React, { useEffect } from 'react'
import { RDList } from '../Shared/Wrappers/RDList'
import {
  getFullDocAnalysis,
  // getRiskAnalysisforSelection,
  // getSelectedTextfromDocument,
  HeaderListInterface,
} from './TopPaneHelpers'
import { ClauseList } from '../AppHelpers'
import { RDGrid } from '../Shared/Wrappers/RDGrid'
import { CircularProgress, Typography } from '@mui/material'
import { RDButton } from '../RDButton'
import { RDFontFamily } from '../../services/ThemeService'
// import { RDButton } from '../RDButton'
//import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
// import { RDFontFamily } from '../../services/ThemeService'
// import { Close } from '@material-ui/icons'
// import { RDGridFormFieldDoubleWidth } from '../Shared/Wrappers/RDGridForm'
// import { RDRadioGroup } from '../Shared/Wrappers/RDRadioGroup'
// import { RDRadio } from '../Shared/Wrappers/RDRadio'
// import { RDFormControlLabel } from '../Shared/Wrappers/RDFormControlLabel'

export interface TopPaneComponentProps {
  token: string
  filter: string
  clauseList: ClauseList[]
}
export enum SelectionOptions {
  'Full Document' = 'full_document',
  'Selection' = 'selection',
}

export const TopPaneComponent = (props: TopPaneComponentProps) => {
  const { token, filter, clauseList } = props
  const [headerList, setHeaderList] = React.useState<HeaderListInterface[]>([])
  const [filteredHeaderList, setFilteredHeaderList] = React.useState<HeaderListInterface[]>([])
  // const [completedRiskAnalysis, setCompletedRiskAnalysis] = React.useState<boolean>(false)
  //const [isOpen, setIsOpen] = React.useState<boolean>(false)
  //const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false)
  const [isAnalyzing, setIsAnalyzing] = React.useState<boolean>(false)
  const [analysisError, setAnalysisError] = React.useState<boolean>(false)
  // const [riskText, setRiskText] = React.useState<string>('')
  // const [selectedOption, setSelectedOption] = React.useState<string>('')
  // const style = {
  //   position: 'relative',
  //   top: '50%',
  //   left: '50%',
  //   transform: 'translate(-50%, -50%)',
  //   width: 300,
  //   height: 300,
  //   padding: 0,
  //   bgcolor: 'background.paper',
  //   boxShadow: 24,
  //   borderRadius: '15px',
  //   zIndex: 2000,
  //   overflow: 'hidden',
  // }

  useEffect(() => {
    setIsAnalyzing(true)
    getFullDocAnalysis(setHeaderList, setAnalysisError, clauseList, token)
  }, [])

  useEffect(() => {
    if (headerList?.length > 0) {
      const filteredHeaderList = JSON.parse(JSON.stringify(headerList))
      if (filter?.length > 0) {
        setFilteredHeaderList(
          filteredHeaderList.filter((header) => header.name.toLowerCase().includes(filter.toLowerCase()))
        )
      } else {
        setFilteredHeaderList(filteredHeaderList)
      }
      setIsAnalyzing(false)
    }
  }, [headerList])

  useEffect(() => {
    const filteredHeaderList = JSON.parse(JSON.stringify(headerList))
    if (filter?.length > 0) {
      setFilteredHeaderList(
        filteredHeaderList.filter((header) => header.name.toLowerCase().includes(filter.toLowerCase()))
      )
    } else {
      setFilteredHeaderList(filteredHeaderList)
    }
  }, [filter])

  useEffect(() => {
    if (analysisError) {
      setIsAnalyzing(false)
    }
  }, [analysisError])
  // const handleScanClick = async () => {
  //   setIsAnalyzing(true)
  //   if (selectedOption === 'full_document') {
  //     getFullDocAnalysis(setHeaderList, clauseList, token)
  //   } else {
  //     const text = await getSelectedTextfromDocument()
  //     const risk = await getRiskAnalysisforSelection(token, text)
  //     if (risk.length > 0) {
  //       setRiskText(risk)
  //       setIsModalOpen(true)
  //     }
  //   }
  // }

  return (
    <RDGrid
      style={{
        height: '100%',
        fontFamily: `"Gotham HTF", "Roboto", "Helvetica Neue", sans-serif`,
        fontSize: '16px',
        width: '100%',
        paddingTop: 0,
      }}>
      <RDGrid container justifyContent={'center'} style={{ paddingTop: 0 }}>
        {/* <RDGrid
          container
          alignItems={'center'}
          direction={'column'}
          justifyContent={'space-around'}
          style={{ paddingTop: 0 }}>
          <RDGrid style={{ paddingTop: 0 }}>
            <RDRadioGroup
              style={{ flexDirection: 'row' }}
              value={selectedOption}
              onChange={(event) => setSelectedOption(event.target.value)}>
              {Object.keys(SelectionOptions).map((k, index) => {
                return (
                  <RDGrid container direction={'row'} padding={0}>
                    <RDGridFormFieldDoubleWidth alignItems={'center'}>
                      <RDFormControlLabel
                        value={SelectionOptions[k]}
                        key={`extraction-method-${index}`}
                        control={<RDRadio />}
                        label={k}
                      />
                      {k === 'Selection' && (
                        <Tooltip
                          title={`Select an option to either scan the full document or selected text on the document for risk. `}
                          placement='top'>
                          <InfoOutlinedIcon />
                        </Tooltip>
                      )}
                    </RDGridFormFieldDoubleWidth>
                  </RDGrid>
                )
              })}
            </RDRadioGroup>
          </RDGrid>
          <RDGrid></RDGrid>
          <RDGrid style={{ paddingTop: 0 }}>
            <RDButton
              sx={{ paddingLeft: 6, paddingRight: 6 }}
              disabled={isAnalyzing || selectedOption === ''}
              onClick={handleScanClick}>
              Risk Scan
            </RDButton>
          </RDGrid>
        </RDGrid> */}
        {/* <Modal
          open={isModalOpen}
          onClose={() => {
            setIsAnalyzing(false)
            setIsModalOpen(false)
          }}>
          <Box sx={style}>
            <RDGrid container style={{ margin: 0, padding: 0 }}>
              <RDGrid
                container
                direction={'row'}
                alignItems={'center'}
                flexWrap={'nowrap'}
                justifyContent={'space-between'}
                style={{ paddingLeft: 6, paddingRight: 6, paddingTop: 6, width: '100%' }}>
                <RDGrid>
                  <Typography fontFamily={RDFontFamily} fontSize={16} marginRight={6}>
                    Risk Analysis
                  </Typography>
                </RDGrid>
                <RDGrid>
                  <RDButton
                    onClick={(evt) => {
                      evt.stopPropagation()
                      setIsAnalyzing(false)
                      setIsModalOpen(false)
                    }}>
                    <Close style={{ height: 20, width: 20 }} />
                  </RDButton>
                </RDGrid>
              </RDGrid>
              <Typography
                variant='body2'
                style={{ marginTop: 12, marginLeft: 12, overflowY: 'scroll', maxHeight: 250 }}>
                {riskText}
              </Typography>
            </RDGrid>
          </Box>
        </Modal> */}
      </RDGrid>
      {filteredHeaderList?.length === 0 && isAnalyzing && (
        <RDGrid container justifyContent={'center'} style={{ paddingTop: '35%', height: '100%' }}>
          <RDGrid>
            <RDGrid>
              <CircularProgress />
            </RDGrid>
          </RDGrid>
          <RDGrid>
            <RDGrid>
              <RDGrid sx={{ padding: 6 }}>{`Analyzing Document`}</RDGrid>
            </RDGrid>
          </RDGrid>
        </RDGrid>
      )}
      {filteredHeaderList?.length > 0 && <RDList list={filteredHeaderList} />}
      {analysisError && (
        <RDGrid
          container
          direction={'column'}
          justifyContent={'center'}
          style={{ paddingLeft: '6px', alignItems: 'center', height: '100%', width: '100%' }}>
          <RDGrid>
            <Typography fontFamily={RDFontFamily} fontSize={16}>
              An Error occured while scanning the document.
            </Typography>
          </RDGrid>
          <RDGrid style={{ paddingTop: '6px' }}>
            <RDButton
              sx={{ paddingLeft: 6, paddingRight: 6 }}
              onClick={() => {
                setAnalysisError(false)
                setIsAnalyzing(true)
                getFullDocAnalysis(setHeaderList, setAnalysisError, clauseList, token)
              }}>
              Rerun Scan
            </RDButton>
          </RDGrid>
        </RDGrid>
      )}
    </RDGrid>
  )
}
