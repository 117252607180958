import { getGraphData } from '../../utilities/microsoft-graph-helpers'
import { AttachmentEditSesssion } from './TopPane/TopPaneHelpers'

export interface AppState {
  authStatus: string
  authToken: string
  userName: string
  isSaveDisabled: boolean
  attachmentEditSession: AttachmentEditSesssion
  lastSavedAt: Date
  filter?: string
  fileFetch?: string
  headerMessage?: string
  errorMessage?: string
}
export interface AppProps {
  title: string
  isOfficeInitialized: boolean
}

export interface ClauseType {
  name: string
  description: string
}

export interface ClauseList {
  type: string
  clause_type: ClauseType[]
}

export interface ContractClauseInterface {
  id: number
  requirement: string
  description: string
  is_default: boolean
  contract_clause_type_id: number
}

export interface ClauseListInterface {
  id: number
  instance_id: number
  name: string
  ai_prompt: string
  ai_prompt_enabled_default: boolean
  section: number
  contract_clauses: ContractClauseInterface[]
}

export const getClauseTypes = async (
  token: string,
  instanceId: number,
  setClauseList: React.Dispatch<React.SetStateAction<ClauseList[]>>
) => {
  try {
    let response = await getGraphData(
      // Call backend api to fetch clauses
      `https://${process.env.REACT_APP_RAINAI}/getcontractclausetypes?instanceid=${instanceId}`,
      token
    )
    if (response.data.data?.contract_clause_type?.length > 0) {
      const clauseList: ClauseList[] = []
      response.data.data?.contract_clause_type?.forEach((clause) => {
        if (clause.contract_clauses?.length > 0) {
          const clause_type = clause.contract_clauses.map((cc) => {
            return { name: cc.description, description: cc.requirement }
          })
          clauseList.push({ type: clause.name, clause_type: clause_type })
        }
      })
      console.log('clauseList', clauseList)
      setClauseList(clauseList)
    }
  } catch (requestError) {
    // This error must be from the Axios request in getGraphData,
    // not the Office.js in writeFileNamesToWorksheet.
    console.log('error', requestError)
  }
}
