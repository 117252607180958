import React, { useState } from 'react'
import { TopPaneComponent } from './TopPane/TopPaneComponent'
import { AppState, ClauseList } from './AppHelpers'
import { TopMenuBar } from './TopMenuBar'
import { RDGrid } from './Shared/Wrappers/RDGrid'
import RDChatBotify from './Shared/Wrappers/RDChatBotify/RDChatBotify'
import { RDButton } from './RDButton'
import { Box, Modal } from '@mui/material'

interface AddInBodyProps {
  state: AppState
  setState: React.Dispatch<React.SetStateAction<AppState>>
  clauseList: ClauseList[]
}

const AddInBody = (props: AddInBodyProps) => {
  const { state, clauseList, setState } = props
  const [botOpen, setBotOpen] = useState<boolean>(false)
  return (
    <RDGrid style={{ paddingTop: 0, width: '100%', height: '100%', overflow: 'hidden' }}>
      <RDGrid container style={{ paddingTop: 0, width: '100%', height: '100%', overflow: 'hidden' }}>
        <TopMenuBar appSetState={setState} appState={state} />
        <TopPaneComponent token={state.authToken} filter={state.filter} clauseList={clauseList} />
        {botOpen && (
          <Modal
            open={botOpen}
            onClose={() => {
              false
              setBotOpen(false)
            }}>
            <Box
              sx={{
                position: 'relative',
                top: '100%',
                left: '100%',
                transform: 'translate(-100%, -100%)',
                width: '100%',
                height: 300,
                padding: 0,
                bgcolor: 'background.paper',
                boxShadow: 24,
                zIndex: 2000,
                overflow: 'hidden',
                paddingBottom: '6px',
              }}>
              <RDChatBotify sessionID='aasdfgd78' token={state.authToken} setBotOpen={setBotOpen}></RDChatBotify>
            </Box>
          </Modal>
        )}
      </RDGrid>
      {!botOpen && (
        <RDButton
          onClick={() => setBotOpen(true)}
          sx={{
            position: 'relative',
            top: '-2%',
            left: '98%',
            transform: 'translate(-100%, -100%)',
            width: '30px',
            height: '30px',
            padding: 0,
            boxShadow: 24,
            zIndex: 2000,
            paddingTop: 0,
          }}>
          <img height='28px' width='28px' src={'assets/raindrop.png'} alt={'raindrop'} />
        </RDButton>
      )}
    </RDGrid>
  )
}

// const SystemSetupTabs = (state: AppState, clauseList: ClauseList[]) => [
//   {
//     name: 'risk_analysis',
//     label: 'Risk Analysis',
//     component: <TopPaneComponent token={state.authToken} filter={state.filter} clauseList={clauseList} />,
//   },
//   {
//     name: 'integration',
//     label: 'Assistant',
//     component: <RDChatBotify sessionID='aasdfgd78' token={state?.authToken}></RDChatBotify>,
//   },
// ]
export default AddInBody
